/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

export class AxiosService {
  static _instance: AxiosService;

  constructor() {
    console.log('[SILLY] AxiosService Constructor');
  }

  static getInstance(): AxiosService {
    if (!this._instance) {
      this._instance = new AxiosService();
    }

    return this._instance;
  }

  static async get(url: string, data?: any) {
    return axios.get(url, {
      ...data,
    });
  }

  static async post(url: string, data?: any) {
    return axios.post(url, data, {});
  }

  static async put(url: string, data?: any) {
    return axios.put(url, data, {});
  }

  static async delete(url: string, data?: any) {
    return axios.delete(url, {
      ...data,
    });
  }
}
