export enum AuthRoutes {
  LOGIN = "/login",
  SIGNUP = "/sign-up",
  CODE_CONFIRMATION = "/code-confirmation",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
}

export enum UserRoutes {
  PROFILE = "/user/profile",
  BOOKINGS = "/user/bookings",
}

export enum AddressRoutes {
  _ROOT = "/user/addresses",
  ADD_ADDRESS = "/user/add-address",
  EDIT_ADDRESS = "/user/edit-address",
}

export enum BookingRoutes {
  _ROOT = "/user/bookings",
}

export enum CleaningBookingRoutes {
  _ROOT = "/user/insta-cleaning-bookings",
  INVOICE_DOWNLOAD = "/user/booking/invoice",
  DAILY = '/user/daily-bookings'
}

export enum HomeRoutes {
  _ROOT = "/",
}

export enum AboutRoutes {
  _ROOT = "/about",
}

export enum ServicesRoutes {
  _ROOT = "/services",
  CAR_WASHING_SLOT = "/services/car-washing/slot",
  CAR_WASHING_PARTNER_LIST = "/services/car-washing/partners",
  CAR_WASHING_PARTNER_PACKAGE_LIST = "/services/car-washing/partners/package",
  CAR_DETAILING_SLOT = "/services/car-detailing/slot",
  CAR_DETAILING_PARTNER_LIST = "/services/car-detailing/partners",
  CAR_DETAILING_PARTNER_PACKAGE_LIST = "/services/car-detailing/partners/package",
  INSTA_CLEANING_PLAN = "/services/insta-cleaning/plan",
  INSTA_CLEANING_CAR_DETAILS = "/services/insta-cleaning/car-details",
  // INSTA_CLEANING_CLEANERS_LIST = "/services/insta-cleaning/cleaners",
  BOOKING_SUMMARY = "/user/services/booking-summary",
  BOOKING_CONFIRMED = "/user/services/booking-confirmed",
  INSTA_CLEANING_BOOKING_SUMMARY = "/user/services/insta-cleaning-booking-summary",
  INSTA_CLEANING_BOOKING_CONFIRMED = "/user/services/insta-cleaning-booking-confirmed",
  PARTNER_REGISTRATION = "/partner/registration",
  INSTA_CLEANING_TIMESLOT = "/user/service/insta-cleaning-select-timeslot"
}

export enum RegisterRoutes {
  _ROOT = "/sign-up",
  CUSTOMER_REGISTER = "/",
}
