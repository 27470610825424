import { EntityBaseModel } from '@devslane/mobx-entity-manager';
import { observable } from 'mobx';
import { CleaningBuildingStore } from '../entities/CleaningBuildingStore';

export interface PackageSpecs {
  seq: number;
  title: string;
  value: boolean;
}

export interface PackageData {
  name: string;
  days: number;
  description: string;
  avail_limit: number;
  actual_price: number;
  discounted_price: number;
  package_type: 'Basic' | 'Premium';
  package_specs: Record<string, PackageSpecs>;
}

export class CleaningBuildingModel extends EntityBaseModel {
  static _store: CleaningBuildingStore;

  @observable
  name!: string;

  @observable
  address_line!: string;

  @observable
  city!: string;

  @observable
  state!: string;

  @observable
  pincode!: string;

  @observable
  country!: string;

  @observable
  latitude!: string;

  @observable
  longitude!: string;

  @observable
  full_address!: string;

  @observable
  package!: PackageData;
}
