import './styles/app.css';
import React, { useEffect } from 'react';
import NavBar from './components/nav-bar/NavBar';
import { BrowserRouter, Switch } from 'react-router-dom';
import CustomerDashboardLazy from './components/CustomerDashboard.lazy';
import PublicRoute from './components/routes/PublicRoute';
import { AppContext } from './App.context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './components/routes/ProtectedRoute';
import CustomerProtectedDashboardLazy from './components/CustomerProtectedDashboard.lazy';
import axios from 'axios';
import { AxiosService } from './axios/AxiosService';
import Cookies from 'js-cookie';

declare global {
  interface Window {
    Razorpay: any;
  }
}
const App: React.FC = () => {
  const { store, toast, storage } = AppContext;

  const token = Cookies.get('auth_token');

  console.log({ token });

  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL;

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  useEffect(() => {
    const authToken = storage.getAuthToken();
    if (!store.userStore.loggedInUser) {
      //&& authToken
      try {
        store.userStore.me();
      } catch (e) {
        toast.showError('Logged Out');
      }
    }
  }, []);

  useEffect(() => {
    AxiosService.getInstance();
  }, []);

  return (
    <div className='flex flex-col h-screen overflow-x-hidden hideScrollBar ' style={{ backgroundColor: '#e3e3e3' }}>
      <ToastContainer hideProgressBar pauseOnFocusLoss={false} />
      <BrowserRouter>
        <NavBar />
        <Switch>
          {/* <ProtectedRoute path={'/admin'} component={AdminDashboardLazy} /> */}
          {/*<ProtectedRoute path={'/partner'} component={PartnerDashboardLazy} />*/}
          {/*<ProtectedRoute path={'/:userId'} component={CustomerDashboardLazy} />*/}
          <ProtectedRoute path={'/user'} component={CustomerProtectedDashboardLazy} />
          <PublicRoute path={'/'} component={CustomerDashboardLazy} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
