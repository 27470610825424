import React from 'react';
import ReactDOM from 'react-dom/client';

import '@fontsource/poppins';
import { init } from '@sentry/react';

import App from './App';
import { Context } from './store/Context';
import { AppContext } from './App.context';
import { ToastService } from './store/services/ToastService';
import { StorageService } from './store/services/StorageService';
import { NavigationService } from './store/services/NavigationService';

import './styles/index.css';

init({
  dsn: 'https://619b95d262a4433b9cfd5dd24cb44b97@o4505030507823104.ingest.sentry.io/4505048019435520',
});

Context.boot({
  storageService: StorageService.getInstance(),
  baseUrl: process.env.REACT_APP_BACKEND_BASE_URL as string,
  toastService: ToastService.getInstance(),
  navigationService: NavigationService.getInstance(),
});

AppContext.boot();

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
